import React from "react"
import { useTranslation } from '@3nvi/gatsby-theme-intl';
import { navigate } from 'gatsby'

import FrIcon from '../../../static/images/fr-icon.png'
import UkIcon from '../../../static/images/uk-icon.png'

export default function Main() {
  const { t, i18n } = useTranslation();

  const changeToFr = (e) => {
    e.preventDefault();
    navigate("/fr/privacy-policy");
  }

  const changeToEn = (e) => {
    e.preventDefault();
    navigate("/en/privacy-policy");
  }

  i18n.changeLanguage()
  return (
    <div className="main__privacy container">
      <div className="container">
        <h1>{t('privacy.title')}</h1>
        <br />
        <div className="lang">
          <a onClick={changeToEn}>
            <img src={UkIcon} alt="english" />
          </a>
          <a onClick={changeToFr}>
            <img src={FrIcon} alt="french" />
          </a>
        </div>
        <br />
        <div>{t('privacy.intro')}</div>
        <br />
        <div>
          <h2>{t('privacy.data_controller.title')}</h2>
          <br />
          <div>{t('privacy.data_controller.part1')}</div>
          <br />
          <div>{t('privacy.data_controller.part2')}</div>
        </div>
        <br />
        <div>
          <h2>{t('privacy.data_collection.title')}</h2>
          <div>
            <div>{t('privacy.data_collection.part1.text1')}</div>
            <ul>
              <li>{t('privacy.data_collection.part1.list1.1')}</li>
              <li>{t('privacy.data_collection.part1.list1.2')}</li>
              <li>{t('privacy.data_collection.part1.list1.3')}</li>
              <li>{t('privacy.data_collection.part1.list1.4')}</li>
            </ul>
            <br />
            <div>{t('privacy.data_collection.part1.text2')}</div>
            <br />
            <div>{t('privacy.data_collection.part1.text3')}</div>
            <br />
            <div>{t('privacy.data_collection.part1.text4')}</div>
            <br />
            <div>{t('privacy.data_collection.part1.text5')}</div>
          </div>
          <br />
          <div>
            <h3>{t('privacy.data_collection.part2.title')}</h3>
            <br />
            <div>{t('privacy.data_collection.part2.text1')}</div>
            <ul>
              <li>{t('privacy.data_collection.part2.list1.1')}</li>
              <li>{t('privacy.data_collection.part2.list1.2')}</li>
              <li>{t('privacy.data_collection.part2.list1.3')}</li>
              <li>{t('privacy.data_collection.part2.list1.4')}</li>
            </ul>
            <br />
            <div>{t('privacy.data_collection.part2.text2')}</div>
            <br />
            <div>{t('privacy.data_collection.part2.text3')}</div>
            <ul>
              <li>
                {t('privacy.data_collection.part2.list2.1.text')}
                <ul>
                  <li>{t('privacy.data_collection.part2.list2.1.1')}</li>
                  <li>{t('privacy.data_collection.part2.list2.1.2')}</li>
                </ul>
              </li>
              <li>
                {t('privacy.data_collection.part2.list2.2.text')}
                <ul>
                  <li>{t('privacy.data_collection.part2.list2.2.1')}</li>
                  <li>{t('privacy.data_collection.part2.list2.2.2')}</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h3>{t('privacy.data_collection.part3.title')}</h3>
            <br />
            <div>{t('privacy.data_collection.part3.text1')}</div>
            <ul>
              <li>{t('privacy.data_collection.part3.list1.1')}</li>
              <li>{t('privacy.data_collection.part3.list1.2')}</li>
              <li>{t('privacy.data_collection.part3.list1.3')}</li>
              <li>{t('privacy.data_collection.part3.list1.4')}</li>
            </ul>
            <br />
            <div>{t('privacy.data_collection.part3.text2')}</div>
            <ul>
              <li>{t('privacy.data_collection.part3.list2.1')}</li>
              <li>{t('privacy.data_collection.part3.list2.2')}</li>
              <li>{t('privacy.data_collection.part3.list2.3')}</li>
              <li>{t('privacy.data_collection.part3.list2.4')}</li>
            </ul>
          </div>
        </div>
        <br />
        <div>
          <h2>{t('privacy.recipients.title')}</h2>
          <br />
          <div>{t('privacy.recipients.text1')}</div>
          <br />
          <div>{t('privacy.recipients.text2')}</div>
          <br />
          <div>{t('privacy.recipients.text3')}</div>
          <br />
          <div>{t('privacy.recipients.text4')}</div>
          <br />
          <div>{t('privacy.recipients.text5')}</div>
          <br />
          <div>{t('privacy.recipients.text6')}</div>
          <br />
          <div>
            <h3>{t('privacy.recipients.part1.title')}</h3>
            <br />
            <div>{t('privacy.recipients.part1.text1')}</div>
            <br />
            <div>{t('privacy.recipients.part1.text2')}</div>
            <br />
            <span>{t('privacy.recipients.part1.email')}</span>
            <br />
            <span>{t('privacy.recipients.part1.phone')}</span>
          </div>
          <div>
            <h3>{t('privacy.recipients.part2.title')}</h3>
            <br />
            <div>{t('privacy.recipients.part2.text1')}</div>
            <br />
            <div>{t('privacy.recipients.part2.text2')}</div>
            <br />
            <ul>
              <li>{t('privacy.recipients.part2.list1.1')}</li>
              <li>{t('privacy.recipients.part2.list1.2')}</li>
              <li>{t('privacy.recipients.part2.list1.3')}</li>
              <li>{t('privacy.recipients.part2.list1.4')}</li>
            </ul>
            <br />
            <div>{t('privacy.recipients.part2.text3')}</div>
            <br />
            <span>{t('privacy.recipients.part2.email')}</span>
            <br />
            <span>{t('privacy.recipients.part2.phone')}</span>
          </div>
          <div>
            <h3>{t('privacy.recipients.part3.title')}</h3>
            <br />
            <div>{t('privacy.recipients.part3.text')}</div>
          </div>
          <br />
          <div>
            <h3>{t('privacy.recipients.part4.title')}</h3>
            <br />
            <div>{t('privacy.recipients.part4.text1')}</div>
            <br />
            <div>{t('privacy.recipients.part4.text2')}</div>
            <br />
            <div>{t('privacy.recipients.part4.text3')}</div>
            <br />
            <ul>
              <li>{t('privacy.recipients.part4.list1.1')}</li>
              <li>{t('privacy.recipients.part4.list1.2')}</li>
              <li>{t('privacy.recipients.part4.list1.3')}</li>
              <li>{t('privacy.recipients.part4.list1.4')}</li>
              <li>{t('privacy.recipients.part4.list1.5')}</li>
            </ul>
            <br />
            <div>{t('privacy.recipients.part4.text4')}</div>
          </div>
        </div>
        <br />
        <div>
          <h2>{t('privacy.sub_contractor.title')}</h2>
          <br />
          <div>{t('privacy.sub_contractor.text1')}</div>
          <br />
          <div>{t('privacy.sub_contractor.text2')}</div>
          <br />
          <div>{t('privacy.sub_contractor.text3')}</div>
          <br />
          <div>{t('privacy.sub_contractor.text4')}</div>
          <br />
          <div>
            <h3>{t('privacy.sub_contractor.part1.title')}</h3>
            <br />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {t('privacy.sub_contractor.part1.table.head.1')}
                  </th>
                  <th>
                    {t('privacy.sub_contractor.part1.table.head.2')}
                  </th>
                  <th>
                    {t('privacy.sub_contractor.part1.table.head.3')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowSpan={3}>{t('privacy.sub_contractor.part1.table.body.cloud')}</th>
                  <th>{t('privacy.sub_contractor.part1.table.body.hosting_service')}</th>
                  <th rowSpan={2}>{t('privacy.sub_contractor.part1.table.body.backups')}</th>
                  <th rowSpan={2}>{t('privacy.sub_contractor.part1.table.body.aws')}</th>
                </tr>
                <tr>
                  <th>{t('privacy.sub_contractor.part1.table.body.emailing')}</th>
                </tr>
                <tr>
                  <th>{t('privacy.sub_contractor.part1.table.body.crm')}</th>
                  <th>{t('privacy.sub_contractor.part1.table.body.hosting')}</th>
                  <th>{t('privacy.sub_contractor.part1.table.body.hubspot')}</th>
                </tr>
                <tr>
                  <th />
                  <th>{t('privacy.sub_contractor.part1.table.body.reporting')}</th>
                  <th>{t('privacy.sub_contractor.part1.table.body.backup')}</th>
                  <th>{t('privacy.sub_contractor.part1.table.body.ga')}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <div>
          <h2>{t('privacy.legislation.title')}</h2>
          <br />
          <div>{t('privacy.legislation.text')}</div>
        </div>
        <br />
      </div>
    </div>
  )
}
